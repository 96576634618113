import { memo } from 'react';
import { IItemOrderbookListItem, IItemOrderbookListItemV2 } from './types';

export const localStorageOrderbookHandler = {
  get: () => {
    const orderbookName = localStorage.getItem('orderbooklistName');
    const orderbookID = localStorage.getItem('orderbooklistID');
    return {
      orderbookName,
      orderbookID,
    };
  },
  set: (orderbookName: string, orderbookID: string) => {
    localStorage.setItem('orderbooklistID', orderbookID);
    localStorage.setItem('orderbooklistName', orderbookName);
  },
  remove: () => {
    localStorage.removeItem('orderbooklistID');
    localStorage.removeItem('orderbooklistName');
  },
};

export const getErrorMessage = (error: unknown) => {
  const err =
    error instanceof Error
      ? // @ts-ignore
        error?.cause?.message || error.message
      : String(error);

  return err;
};

export const memoizeChild = (component) => {
  const circular = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (key.startsWith('_')) return; // Don't compare React's internal props.
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) return;
        seen.add(value);
      }
      // eslint-disable-next-line consistent-return
      return value;
    };
  };

  return memo(component, (prevProps, nextProps) => {
    const prev = JSON.stringify(prevProps, circular());
    const next = JSON.stringify(nextProps, circular());
    return prev === next;
  });
};

export const transformNewOrderbookListToExisting = (
  newResponse: IItemOrderbookListItemV2,
): IItemOrderbookListItem => {
  const tradeable = newResponse.tradable ? 1 : 0;
  const bidObject = {};
  const offerObject = {};
  newResponse.bid.forEach((value, index) => {
    const priceNameKey = `price${index + 1}`;
    bidObject[priceNameKey] = value.price;

    const queNumNameKey = `que_num${index + 1}`;
    bidObject[queNumNameKey] = value.que_num;

    const valueNameKey = `volume${index + 1}`;
    bidObject[valueNameKey] = value.volume;
  });

  newResponse.offer.forEach((value, index) => {
    const priceNameKey = `price${index + 1}`;
    offerObject[priceNameKey] = value.price;

    const queNumNameKey = `que_num${index + 1}`;
    offerObject[queNumNameKey] = value.que_num;

    const valueNameKey = `volume${index + 1}`;
    offerObject[valueNameKey] = value.volume;
  });

  return {
    id: newResponse.id,
    symbol: newResponse.symbol,
    symbol_2: newResponse.symbol,
    symbol_3: newResponse.symbol,
    name: newResponse.name,
    status: newResponse.status,
    lastprice: newResponse.lastprice,
    previous: newResponse.previous,
    change: newResponse.change,
    percentage_change: newResponse.percentage_change,
    open: newResponse.open,
    high: newResponse.high,
    low: newResponse.low,
    close: newResponse.close,
    volume: newResponse.volume,
    value: newResponse.value,
    average: newResponse.average,
    bid: bidObject,
    offer: offerObject,
    frequency: newResponse.frequency,
    fbuy: newResponse.fbuy,
    fsell: newResponse.fsell,
    fnet: newResponse.fnet,
    fpercent: newResponse.fpercent,
    tradeable,
    notation: newResponse.notation,
    uma: newResponse.uma,
    GUID: null,
    is_foreignbs_exist: newResponse.has_foreign_bs,
    exchange: newResponse.exchange,
    country: null,
    foreign: null,
    domestic: null,
    up: null,
    down: null,
    unchanged: null,
    iepiev: newResponse.iepiev,
    ara: newResponse.ara,
    arb: newResponse.arb,
    company_type: newResponse.company_type,
    corp_action: newResponse.corp_action,
    market_data: newResponse.market_data,
    auto_reject_estimation: newResponse.auto_reject_estimation,
  };
};
